import { Controller } from "stimulus"
import { getCachedPosition } from "./clock_in_map_controller"

export default class extends Controller {
  static targets = ["lat", "lng"]

  connect () {
    this.saveLocation()
  }

  saveLocation () {
    const pos = getCachedPosition()
    if (!pos) { return }

    if (this.hasLatTarget) {
      this.latTarget.value = pos.lat
    }

    if (this.hasLngTarget) {
      this.lngTarget.value = pos.lng
    }
  }
}
