import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["container"]
  static values = { text: String, delay: Number, speed: Number }

  connect () {
    var that = this
    setTimeout(function() {
      that.typing()
    }, that.delayValue)

  }

  typing () {
    var i = 0
    var that = this

    function text() {
      if (i < that.textValue.length) {
        that.containerTarget.innerHTML += that.textValue.charAt(i)
        i++
        if (that.textValue.charAt(i - 1) === "." || that.textValue.charAt(i - 1) === ",") {
          setTimeout(text, (that.speedValue * 15))
        } else {
          setTimeout(text, that.speedValue)
        }
      }
    }
    text()
  }

}
