import { Controller } from "stimulus"
import { Request } from '@rails/request.js'

export default class extends Controller {
  static classes = ["close"]
  static values = { modal: String }

  close () {
    var modal = document.getElementById(this.modalValue)

    var closeClasses = modal.dataset.modalCloseClass.split(' ')
    modal.classList.add(...closeClasses)

    if (modal.dataset.modalCloseActionValue) {
      new Request('POST', modal.dataset.modalCloseActionValue).perform()
    }
  }
}
