import { Loader } from "@googlemaps/js-api-loader"
import { debug } from "./debug"

let _loader = null

// https://console.cloud.google.com/apis/credentials/key/b4e0e596-a3f5-4de7-a00a-b98660b5855e?folder=&organizationId=&project=workforce-two&supportedpurview=project
const getLoadMapPromise = () => {
  if (_loader) {
    debug("already have a map loader")
    return _loader
  } else {
    debug("start map loader")
    _loader = new Loader({
      apiKey: window.google_maps_config.key,
      version: "weekly"
    }).load()
    return _loader
  }
}

export { getLoadMapPromise }
