import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["seconds", "minutes", "hours"]
  static values = { time: Number }

  connect() {
    this.interval = setInterval(this.setTime.bind(this), 1000)

    var start_time = new Date(this.timeValue * 1000).getTime()
    var current_time = new Date().getTime()
    this.totalSeconds = Math.round((current_time - start_time) / 1000)
    this.setTime()
  }

  disconnect () {
    if (this.interval) {
      clearInterval(this.interval)

      delete this.interval
      delete this.totalSeconds
    }
  }

  setTime () {
    this.totalSeconds += 1
    this.secondsTarget.innerHTML = this.pad(this.totalSeconds % 60)
    this.minutesTarget.innerHTML = this.pad(parseInt(this.totalSeconds / 60) % 60)
    this.hoursTarget.innerHTML = this.pad(parseInt(this.totalSeconds / 3600))
  }

  pad (val) {
    var valString = val + ""
    if (valString.length < 2)
    {
      return "0" + valString
    }
    else
    {
      return valString
    }
  }
}
