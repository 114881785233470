import { Controller } from "stimulus"
import { Request } from '@rails/request.js'

export default class extends Controller {
  static classes = ["open", "close"]
  static targets = ["modal", "container"]
  static values = { closeAction: String }

  connect () {
    var openClasses = this.openClass.split(' ')
    this.containerTarget.classList.add(...openClasses)
  }

  close () {
    var closeClasses = this.closeClass.split(' ')
    this.modalTarget.classList.add(...closeClasses)
    if (this.hasCloseActionValue) {
      new Request('POST', this.closeActionValue).perform()
    }
  }
}
