import { Controller } from "stimulus"

export default class extends Controller {
  static classes = ["enableToggle", "disableToggle", "enableBg", "disableBg"]
  static targets = ["toggle", "field"]

  toggle () {
    const checked = this.element.dataset.checked === "false"
    this.element.classList.toggle(this.enableBgClass, checked)
    this.element.classList.toggle(this.disableBgClass, !checked)
    this.toggleTarget.classList.toggle(this.enableToggleClass, checked)
    this.toggleTarget.classList.toggle(this.disableToggleClass, !checked)
    this.fieldTarget.value = checked
    this.element.dataset.checked = checked

    if (checked) {
      window.nativeRequestPushNotificationPermissions?.()
    }
  }
}
  


  