import { Controller } from "stimulus"

export default class extends Controller {
  static classes = ["body"]

  connect() {
    if (this.hasBodyClass) {
      document.body.classList.add(this.bodyClass)
    }
    window.party.screen({
      size: window.party.minmax(10, 17),
      count: window.party.variation(300 * (window.innerWidth / 1980), 0.4),
      angle: -180,
      spread: 80,
      angularVelocity: window.party.minmax(0, 4),
      shape: ['star', 'rectangle'],
    })
  }
} 