import { Controller } from "stimulus"
import { Turbo } from "@hotwired/turbo-rails"
import { Request } from '@rails/request.js'

export default class extends Controller {
  static values = {
    inviteUrl: String,
    inviteState: String,
    sms: String,
    redirectUrl: String,
    delay: Number,
    phoneNumbers: Array,
  }

  launchNativeSMSComposer(event) {
    if (window.nativeLaunchSMSComposer) {
      event.preventDefault() // cancels default behavior (following an "sms://" link. does NOT prevent next stimulus action (markWorkersAsInvited) from running)
      window.nativeLaunchSMSComposer(this.smsValue, this.phoneNumbersValue, this.redirectUrlValue)
    }
  }

  markWorkersAsInvited() {
    new Request('POST', this.inviteUrlValue, { body: JSON.stringify({ invite_state: this.inviteStateValue }) }).perform()

    if (!window.nativeLaunchSMSComposer) {
      const redirect = this.redirectUrlValue
      setTimeout(() => Turbo.visit(redirect), this.delayValue)
    }
  }
}
