import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["option", "input"]
  static values = { fields: String }

  selectRadioOption(event) {
    this.optionTargets.forEach((el) => {
      el.classList.toggle("active", event.target === el)
    })
    this.inputTarget.value = event.target.getAttribute("data-value")
  }
}
