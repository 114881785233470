import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["element"]
  static classes = [ "show", "hide" ]
  static values = { show: String }

  show() {
    this.elementTarget.classList.remove(this.hideClass)
    this.elementTarget.classList.add(this.showClass)
  }

  hide() {
    const that = this

    setTimeout(() => {
      that.elementTarget.classList.remove(that.showClass)
      that.elementTarget.classList.add(that.hideClass)
    }, 250)
  }

  toggle(event) {
    if (event.currentTarget.value === this.showValue) {
      this.show()
    } else {
      this.hide()
    }
  }
}
