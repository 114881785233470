import { Controller } from "stimulus"

export default class extends Controller {
  
  static targets = ["field", "errorMessage"]
  static classes = ["hidden"]
  static values = { invalid: Array }

  check() {
    if (this.invalidValue.some(word => this.fieldTarget.value.toLowerCase().includes(word))) {
      this.errorMessageTarget.classList.remove(this.hiddenClass)
    } else {
      this.errorMessageTarget.classList.add(this.hiddenClass)
    }
  }

}