import { Controller } from "stimulus"

export default class extends Controller {
  submitLater(e) {
    const that = this
    setTimeout(() => that.submit(e), 250)
  }

  submit(e) {
    // let other controllers act first
    if (e.defaultPrevented) { return }

    // abort if not connected anymore
    if (!document.body.contains(this.element)) { return }

    if (this.element instanceof HTMLFormElement) {
      this.element.requestSubmit()
    } else {
      this.element.querySelector("form")?.requestSubmit()
    }
  }
}
