import { Controller } from "stimulus"
import intlTelInput from 'intl-tel-input'

export default class extends Controller {
  static targets = ["source", "country"]

  async connect () {
    const that = this
    that.iti = intlTelInput(this.sourceTarget, {
      initialCountry: "auto",
      preferredCountries: ["us", "gb", "ca"],
      geoIpLookup: async (cb) => {
        const request = await fetch("https://ipinfo.io/json?token=e8fc46a776dd7b")
        const json = await request.json()

        const country = (json && json.country) ? json.country : "us"
        cb(country)
        that.countryTarget.value = country
      },
    })
  }

  storeCountry () {
    this.countryTarget.value = this.iti.getSelectedCountryData().iso2
  }
}
