import { Controller } from "stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static targets = ["purchaseButton", "purchaseView", "successView", "failureView"]
  static classes = ["processing", "body", "hidden"]
  static values = { successPath: String, failurePath: String, type: String }

  connect () {
    if (!window.nativeInAppPurchase) {
      if (this.hasFailurePathValue) {
        const url = new URL(this.failurePathValue, document.baseURI)
        url.searchParams.append("error", "In app purchases are disabled. Please enable them, and update this app. Error: HT204396")
        Turbo.visit(url)
      } else {
        this.element.remove()
      }
    }

    if (this.hasTypeValue && this.typeValue === "modal") {
      document.body.classList.add(this.bodyClass)
    }
  }

  disconnect () {
    delete window.nativeInAppPurchaseCallback
  }

  purchase () {
    var hiddenClass = this.hiddenClass
    if (this.hasTypeValue) {
      var type = this.typeValue
    }

    if (this.hasPurchaseViewTarget && this.hasSuccessViewTarget && this.hasFailureViewTarget) {
      var purchaseView = this.purchaseViewTarget
      var successView = this.successViewTarget
      var failureView = this.failureViewTarget
    }

    if (this.hasSuccessPathValue && this.hasFailurePathValue) {
      var successPath = this.successPathValue
      var failurePath = this.failurePathValue
    }

    const processingClass = this.processingClass
    const button = this.purchaseButtonTarget
    const buttonTextWas = button.innerHTML

    button.classList.add(processingClass)
    button.innerHTML = "Hold tight..."

    // the native layer will call this once `window.nativeInAppPurchase` completes
    // `success` is a boolean (so false means payment failed). `error` is an optional string.
    window.nativeInAppPurchaseCallback = ((success, error) => {
      button.innerHTML = buttonTextWas
      button.classList.remove(processingClass)

      if (type === "modal") {

        if (success) {
          purchaseView.classList.add(hiddenClass)
          successView.classList.remove(hiddenClass)
        } else {
          purchaseView.classList.add(hiddenClass)
          failureView.classList.remove(hiddenClass)
        }

      } else {

        if (success) {
          Turbo.visit(successPath)
        } else {
          console.error(error)

          // copy implementation @ https://github.com/hotwired/turbo/blob/main/src/core/url.ts#L4
          const url = new URL(failurePath, document.baseURI)
          url.searchParams.append("error", error)
          Turbo.visit(url)
        }

      }
    })

    // TODO: generic attribute name
    const productId = this.purchaseButtonTarget.getAttribute("data-replace-value")
    window.nativeInAppPurchase(productId)
  }
}
