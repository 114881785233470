import { Controller } from "stimulus"
import { styles } from "./helpers/map_styles"
import { getLoadMapPromise } from "./helpers/map_loader"
import { debug } from "./helpers/debug"

export default class extends Controller {
  static targets = ["map", "location"]
  static values = { lat: Number, lng: Number }

  initialize() {
    this.loaderPromise = getLoadMapPromise()
  }

  async connect() {
    const instance = this

    debug("start connect()")
    await instance.loaderPromise
    debug("Maps API loaded")

    const center = { lat: this.latValue, lng: this.lngValue }
    const map = new google.maps.Map(instance.mapTarget, {
      center,
      zoom: 11,
      disableDefaultUI: true,
      mapTypeControl: false,
      zoomControl: false,
      scaleControl: false,
      gestureHandling: "none",
      styles: styles()
    })
    new google.maps.Marker({
      position: center,
      map,
      icon: window.google_maps_config.svg_marker,
    })

    const geocoder = new google.maps.Geocoder()
    geocoder.geocode({ location: center }, (results, status) => {
      debug("geocoder returned with status: " + status)

      if (status === "OK") {
        if (results[0]) {
          map.setZoom(14)
          var address_number
          var address_street
          var address_city
          results[0].address_components.forEach(function(item) {
            if (item.types.includes("street_number")) {
              address_number = item.short_name
            } else if (item.types.includes("route")) {
              address_street = item.short_name
            } else if (item.types.includes("locality")) {
              address_city = item.short_name
            }
          })
          this.locationTarget.innerHTML = address_number + " " + address_street + ", " + address_city
        } else {
          this.locationTarget.innerHTML = "Clock-in location not found"
          this.mapTarget.innerHTML = "No location"
        }
      } else {
        this.locationTarget.innerHTML = "Clock-in location not found"
        this.mapTarget.innerHTML = "No location"
      }
    })

  }
}
