import { Controller } from "stimulus"
import { getLoadMapPromise } from "./helpers/map_loader"
import { styles } from "./helpers/map_styles"
import { debug } from "./helpers/debug"

const CACHE_KEY = "ClockInMapController.cachedPosition"

const getCachedPosition = function () {
  const lastPos = window.localStorage.getItem(CACHE_KEY)
  if (lastPos) {
    return JSON.parse(lastPos)
  }
}

const setCachedPosition = function (pos) {
  window.localStorage.setItem(CACHE_KEY, JSON.stringify(pos))

  // https://gist.github.com/TakashiSasaki/4282903
  // HTML5 StorageEvent does not fire "storage" event in the same window (only other ones). So here we self-fire:
  window.dispatchEvent(new Event('storage'))
}

const delay = t => new Promise(resolve => setTimeout(resolve, t))

export { getCachedPosition }

export default class extends Controller {
  static targets = ["map"]
  static values = { delay: Number }

  initialize() {
    this.loaderPromise = getLoadMapPromise()
    this.getPosPromise = this.getPosition()
  }

  getPosition () {
    return delay(this.delayValue).then(() => new Promise((resolve, reject) =>  {
      if (navigator.geolocation) {
        return navigator.geolocation.getCurrentPosition(resolve, reject)
      } else {
        reject()
      }
    }
    ))
  }

  async connect() {
    const instance = this

    debug("start connect()")
    await instance.loaderPromise
    debug("Maps API loaded")

    const center = getCachedPosition()
    const map = new google.maps.Map(instance.mapTarget, {
      center,
      zoom: 11,
      disableDefaultUI: true,
      mapTypeControl: false,
      zoomControl: false,
      scaleControl: false,
      gestureHandling: "none",
      styles: styles()
    })

    const rawPos = await instance.getPosPromise
    debug("have current location")

    const position = {
      lat: rawPos.coords.latitude,
      lng: rawPos.coords.longitude,
    }

    map.setCenter(position)
    map.setZoom(14)
    new google.maps.Marker({
      position,
      map,
      icon: window.google_maps_config.svg_marker,
    })

    setCachedPosition(position)
  }
}
