import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["picker", "content", "replace"]
  static classes = ["picker", "content"]

  picker(event) {
    var pickerClass = this.pickerClass
    this.pickerTargets.forEach(t => t.classList.remove(pickerClass))
    event.currentTarget.classList.add(pickerClass)
  }

  content(event) {
    var contentClass = this.contentClass
    var contentElement = event.currentTarget.getAttribute("data-child")
    this.contentTargets.forEach(t => t.classList.remove(contentClass))
    document.getElementById(contentElement).classList.add(contentClass)
  }

  replace(event) {
    this.replaceTarget.setAttribute("data-replace-value", event.currentTarget.getAttribute("data-replace-value"))
  }
}
