// needed for https://github.com/hotwired/turbo/issues/97#issuecomment-757224391
import "form-request-submit-polyfill"

import { Turbo } from "@hotwired/turbo-rails"
window.Turbo = Turbo

import * as ActiveStorage from "@rails/activestorage"

ActiveStorage.start()

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("../controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

import "stylesheets/application"
import "intl-tel-input/build/css/intlTelInput.css"

// this seems to be adding elements to the DOM twice
// https://party.js.org/docs/migrating-v1-v2/ is lazy and doesn't require this; maybe we should upgrade
window.party = require("party-js")
document.documentElement.addEventListener("turbo:load", () => window.party.init())
