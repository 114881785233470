import { Controller } from "stimulus"
import { Turbo } from "@hotwired/turbo-rails"
import { Request } from '@rails/request.js'

export default class extends Controller {

  static targets = ["manualInviteButton", "manualName", "manualPhone", "usersContacts", "contactsWrapper", "searchBar", "inviteButton", "inviteText", "selectedCheck"]
  static classes = ["hidden", "disabled", "selected"]
  static values = { sms: String, redirectUrl: String, android: Boolean }

  displayContacts(contacts) {
    const htmlString = contacts
      .map((contact) => {
        return `
          <div data-action="click->native-contact#add" class="flex relative space-x-1.5 items-baseline border-transparent focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 placeholder-white py-4 px-4">
              <h4 class="leading-none space-x-1"><span id="firstName">${contact[0]}</span><span id="lastName">${contact[1]}</span></h4>
              <span id="phone" class="text-sm leading-none">${contact[2]}</span>
              <div class="${this.contactsToInvite.some(e => e.phone === contact[2]) ? '' : 'hidden'} check w-6 h-6 border border-transparent rounded-full shadow-md text-white bg-green-600 flex justify-center items-center space-x-2 absolute right-5 -mt-1">
                <svg xmlns="http://www.w3.org/2000/svg" class="stroke-1 h-7 text-white stroke-current" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                </svg>
              </div>
          </div>
      `
      })
      .join('')
    this.usersContactsTarget.innerHTML = htmlString
  }

  connect() {
    var that = this
    this.contactsToInvite = []

    window.nativeContactList && window.nativeContactList()

    window.nativeContactListCallback = ((contacts) => {
      // access master list of contacts in all methods
      that.contacts = contacts.sort((a, b) => a[0].localeCompare(b[0]))

      // render contacts
      that.contactsWrapperTarget.classList.remove(that.hiddenClass)
      that.inviteButtonTarget.classList.remove(that.hiddenClass)
      this.manualInviteButtonTarget.classList.add(that.hiddenClass)
      that.displayContacts(contacts)
    })
  }

  disconnect () {
    delete window.nativeContactListCallback
  }

  search() {
    var searchString = this.searchBarTarget.value.toLowerCase()
    const filteredContacts = this.contacts.filter((contact) => {
      return (
        contact[0].toLowerCase().includes(searchString) ||
            contact[1].toLowerCase().includes(searchString) ||
            contact[2].includes(searchString)
      )
    })
    this.displayContacts(filteredContacts)
    this.usersContactsTarget.scrollTop = 0
  }

  add(event) {
    var that = this
    var contactFirstName = event.currentTarget.querySelector("#firstName").innerHTML
    var contactLastName = event.currentTarget.querySelector("#lastName").innerHTML
    var contactPhone = event.currentTarget.querySelector("#phone").innerHTML

    // if contact already selected, then remove it from invite array. otherwise, add it to array
    if (event.currentTarget.classList.contains(this.selectedClass)) {
      event.currentTarget.classList.remove(this.selectedClass)
      event.currentTarget.querySelector(".check").classList.add(this.hiddenClass)
      this.contactsToInvite = this.contactsToInvite.filter(function(obj) {
        return obj.phone !== contactPhone
      })
    } else {
      event.currentTarget.classList.add(this.selectedClass)
      event.currentTarget.querySelector(".check").classList.remove(this.hiddenClass)
      this.contactsToInvite.push({ first_name: contactFirstName, last_name: contactLastName, phone: contactPhone })

      if (this.searchBarTarget.value !== '') {
        setTimeout(() => {
          // reset search bar state once searched item selected
          that.searchBarTarget.value = ''
          this.displayContacts(this.contacts)
          that.usersContactsTarget.scrollTop = 0
        }, 800)

      }
    }

    this.updateButtonUI()
  }

  addManual() {
    this.contactsToInvite.push({ first_name: this.manualNameTarget.value, last_name: null, phone: this.manualPhoneTarget.value })
    this.updateButtonUI()
  }

  removeManual(event) {
    this.contactsToInvite = this.contactsToInvite.filter(function(obj) {
      return obj.phone !== event.currentTarget.parentNode.parentNode.parentNode.querySelector("#worker_phone").value
    })
    this.updateButtonUI()
  }

  updateButtonUI () {
    // if contacts have been selected, then show button and invite name list
    if (this.contactsToInvite.length > 0) {
      this.inviteButtonTarget.classList.remove(this.disabledClass)
      this.inviteButtonTarget.disabled = false
      const inviteString = this.contactsToInvite
        .map((contact) => {
          return contact.first_name
        })

      var finalText
      if (inviteString.length === 1) {
        finalText = inviteString[0]
      } else if (inviteString.length === 2) {
        finalText = inviteString.join(" & ")
      } else if (inviteString.length === 3) {
        finalText = inviteString[0] + ", " + inviteString[1] + " & " + inviteString[2]
      } else {
        finalText = inviteString[0] + ", " + inviteString[1] + " & " + (inviteString.length - 2) + " others"
      }

      this.inviteTextTarget.innerHTML = "to " + finalText
      this.inviteTextTarget.classList.remove(this.hiddenClass)
    } else {
      this.inviteButtonTarget.classList.add(this.disabledClass)
      this.inviteButtonTarget.disabled = true
      this.inviteTextTarget.classList.add(this.hiddenClass)
    }

    const stringNumbers = this.contactsToInvite
      .map((contact) => {
        return encodeURI(contact.phone)
      })
      .join(',')

    var href
    if (this.androidValue) {
      href = "sms:" + stringNumbers + "?body=" + encodeURI(this.smsValue)
    } else {
      href = "sms://open?addresses=" + stringNumbers + "&body=" + encodeURI(this.smsValue)
    }

    this.inviteButtonTarget.setAttribute("href", href)
  }

  send(event) {
    if (!event.currentTarget.classList.contains(this.disabledClass)) {
      new Request('POST', "/workers/native_invite", { body: JSON.stringify(this.contactsToInvite) }).perform()

      const redirect = this.redirectUrlValue
      setTimeout(() => Turbo.visit(redirect), 500)
    }
  }
}
